import { render, staticRenderFns } from "./agenda-item.vue?vue&type=template&id=3b86b973&scoped=true&"
import script from "./agenda-item.vue?vue&type=script&lang=js&"
export * from "./agenda-item.vue?vue&type=script&lang=js&"
import style0 from "./agenda-item.vue?vue&type=style&index=0&id=3b86b973&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b86b973",
  null
  
)

export default component.exports